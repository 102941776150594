import CBOR from 'cbor'
// import * as base64url from "cbor/lib/encoder";
import base64url from './base64url-arraybuffer'

export function ab2str (buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf))
}

export function ab2b64 (buf) {
    return btoa(ab2str(buf))
}

export function ab2json (buf) {
    return JSON.parse(ab2str(buf))
}

export function ab2hex (buf) {
    return Array.prototype.map
        .call(new Uint8Array(buf), x => ('00' + x.toString(16)).slice(-2))
        .join('')
}

export function cbor2json (buf) {
    return CBOR.decode(ab2hex(buf))
}

export function decodeCredential (savedCred) {
    return {
        ...savedCred,
        rawId: ab2hex(savedCred.rawId),
        response: {
            ...savedCred.response,
            // It is CBOR object but it's not really that useful to see it
            // attestationObjectCbor: cbor2json(savedCred.response.attestationObject),
            attestationObjectHex: ab2hex(savedCred.response.attestationObject),
            clientDataJSON: ab2json(savedCred.response.clientDataJSON),
        },
    }
}

export function decodeAssertion (assertion) {
    return {
        ...assertion,
        rawId: ab2hex(assertion.rawId),
        response: {
            ...assertion.response,
            authenticatorDataHex: ab2hex(assertion.response.authenticatorData),
            clientDataJSON: ab2json(assertion.response.clientDataJSON),
            signatureHex: ab2hex(assertion.response.signature),
        },
    }
}

// Don't do this in production, generate something sutably random on the server
export function generateId () {
    const ab = new Uint8Array(16)

    for (let i = 0; i < 16; i++) {
        ab[i] = Math.round(Math.random() * 255)
    }

    return ab
}

// Don't do this in production, generate something cryptographically safe on the server
export function generateChallenge () {
    const ab = new Uint8Array(32)

    for (let i = 0; i < 32; i++) {
        ab[i] = Math.round(Math.random() * 255)
    }

    return ab.buffer
}

export function preformatMakeCredReq (makeCredReq) {
    makeCredReq.challenge = base64url.decode(makeCredReq.challenge)
    makeCredReq.user.id = base64url.decode(makeCredReq.user.id)
    for (let i = 0; i < makeCredReq.excludeCredentials.length; i++) {
        makeCredReq.excludeCredentials[i].id = base64url.decode(makeCredReq.excludeCredentials[i].id)
    }

    return makeCredReq
}

export function preformatGetAssertReq (getAssert) {
  getAssert.challenge = base64url.decode(getAssert.challenge)

  if (getAssert.allowCredentials) {
    for (let i = 0; i < getAssert.allowCredentials.length; i++) {
      getAssert.allowCredentials[i].id = base64url.decode(getAssert.allowCredentials[i].id)
    }
  }

  return getAssert
};

export function publicKeyCredentialToJSON (pubKeyCred) {
    if (pubKeyCred instanceof Array) {
        const arr = []
        for (const i of pubKeyCred) { arr.push(publicKeyCredentialToJSON(i)) }

        return arr
    }

    if (pubKeyCred instanceof ArrayBuffer) {
        return base64url.encode(pubKeyCred)
    }

    if (pubKeyCred instanceof Object) {
        const obj = {}

        for (const key in pubKeyCred) {
            obj[key] = publicKeyCredentialToJSON(pubKeyCred[key])
        }

        return obj
    }

    return pubKeyCred
}
